import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "@firebolt-dev/jsx/jsx-runtime";
import {cloneElement} from 'react';
import {useMDXComponents} from 'firebolt';
const MDXLayout = function MDXWrapper({children}) {
  const components = useMDXComponents();
  return cloneElement(children, {
    components
  });
};
function _createMdxContent(props) {
  const _components = {
    code: "code",
    h1: "h1",
    h2: "h2",
    p: "p",
    pre: "pre",
    span: "span",
    ...props.components
  }, {Meta} = _components;
  if (!Meta) _missingMdxReference("Meta", true);
  return _jsxs(_Fragment, {
    children: [_jsx(Meta, {
      title: "Getting Started: Quick Start",
      description: "Learn how to quickly get started with a new Firebolt app."
    }), "\n", _jsx(_components.h1, {
      children: "Getting Started"
    }), "\n", _jsx(_components.p, {
      children: "This guide will get you familiar with Firebolt and show you how to quickly create any kind of app for the web.\nFirebolt is built with simplicity in mind and its goal is to reduce complexity without also reducing functionality."
    }), "\n", _jsx(_components.h2, {
      children: "Create App"
    }), "\n", _jsx(_components.p, {
      children: "First lets create a new app using the Firebolt CLI:"
    }), "\n", _jsx(_components.pre, {
      className: "shiki shiki-themes github-light github-dark",
      style: {
        "--shiki-light": "#24292e",
        "--shiki-dark": "#e1e4e8",
        "--shiki-light-bg": "#fff",
        "--shiki-dark-bg": "#24292e"
      },
      tabIndex: "0",
      children: _jsxs(_components.code, {
        children: [_jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: "npm"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: " create"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: " firebolt@latest"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line"
        })]
      })
    }), "\n", _jsx(_components.p, {
      children: "You'll be asked to enter a project name and the CLI will create an app for you in a directory with the same name."
    }), "\n", _jsx(_components.h2, {
      children: "Structure"
    }), "\n", _jsx(_components.p, {
      children: "A brand new Firebolt app looks like this:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        children: "my-app/\n├─ routes/\n│  ├─ _layout.js\n│  └─ index.js\n├─ firebolt.config.js\n└─ package.json\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["The ", _jsx(_components.code, {
        children: "routes"
      }), " directory is where all content that is accessible by a URL goes: pages, layouts, assets, virtual assets and api routes."]
    }), "\n", _jsxs(_components.p, {
      children: ["The ", _jsx(_components.code, {
        children: "firebolt.config.js"
      }), " file lets you configure how your app runs."]
    }), "\n", _jsx(_components.h2, {
      children: "Development"
    }), "\n", _jsx(_components.p, {
      children: "Inside your project folder you can start the app in development mode:"
    }), "\n", _jsx(_components.pre, {
      className: "shiki shiki-themes github-light github-dark",
      style: {
        "--shiki-light": "#24292e",
        "--shiki-dark": "#e1e4e8",
        "--shiki-light-bg": "#fff",
        "--shiki-dark-bg": "#24292e"
      },
      tabIndex: "0",
      children: _jsxs(_components.code, {
        children: [_jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: "npm"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: " run"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: " dev"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line"
        })]
      })
    }), "\n", _jsx(_components.p, {
      children: "Open your app in the browser to view it, and make any changes to files in your project to trigger a rebuild."
    })]
  });
}
export default function MDXContent(props = {}) {
  return _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  });
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
